<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <h1 style="font-size: 1.5rem; margin: 20px auto; font-weight: bold; color: brown;">N3 ခန်းဂျီးလေ့ကျင့်ခြင်း</h1>
    <!-- <KanjiTable/> -->
    <N3Kanji />
    <DataPicker />
    
  </div>
</template>

<script>

// import KanjiTable from './components/KanjiTable.vue'
import N3Kanji from './N3Kanji.vue'
import DataPicker from './DataPicker.vue'

export default {
  name: 'App',
  components: {
    // KanjiTable,
    N3Kanji,
    DataPicker
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
