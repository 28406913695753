
import Vue from 'vue'
import App from './App.vue'
// import router from './router'
// import store from './store'
import 'buefy/dist/buefy.css'
import '@fortawesome/fontawesome-free/js/all.js';
// import api from '@/apis/v1/recordings'

import Buefy from 'buefy'

//fontawesome
import { library } from '@fortawesome/fontawesome-svg-core'
// import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(far, fab)
Vue.component('fa-icon', FontAwesomeIcon)
Vue.component('vue-fontawesome', FontAwesomeIcon)
Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas',
})

// import {
//   applyPolyfills,
//   defineCustomElements,
// } from '@aws-amplify/ui-components/loader';


Vue.config.productionTip = false

new Vue({
  // router,
  // store,
  render: h => h(App),
}).$mount('#app')

