<template>
    <span>
        <b-field grouped group-multiline>
            <b-field label="Locale">
                <b-select v-model="locale">
                    <option v-for="(code, i) in langTag" :key="i" :value="code">{{code}}</option>
                </b-select>
            </b-field>
        </b-field>
        <b-field>
            <b-switch v-model="bars">Bars</b-switch>
        </b-field>
        <b-datepicker
            inline
            v-model="date"
            :events="events"
            :locale="locale"
            :indicators="indicators"
            :unselectable-dates="unselectableDates"
            >
        </b-datepicker>
    </span>
</template>

<script>
    const langTag = ["ja-JP", "ko-KR", "zh-CN","my-BM","bu-BM","mm-BM","my-BU","bu-BU","mm-BU","my-BR","bu-BR","Qaag", "th-TH", "vi-VN", "hi-IN", "km-KH", "ar-SA", "el-GR", "he-IL", "ta-IN", "bn-BD"]
    const thisMonth = new Date().getMonth()

    export default {
        computed: {
            indicators() {
                return this.bars ? 'bars' : 'dots'
            }
        },
        data() {
            return {
                date: new Date(2017, thisMonth, 1),
                langTag: langTag,
                locale: 'ja-JP',
                events: [
                    new Date(2017, thisMonth, 2),
                    new Date(2017, thisMonth, 6),
                    {
                        date: new Date(2017, thisMonth, 6),
                        type: 'is-info'
                    },
                    {
                        date: new Date(2017, thisMonth, 8),
                        type: 'is-danger'
                    },
                    {
                        date: new Date(2017, thisMonth, 10),
                        type: 'is-success'
                    },
                    {
                        date: new Date(2017, thisMonth, 10),
                        type: 'is-link'
                    },
                    new Date(2017, thisMonth, 12),
                    {
                        date: new Date(2017, thisMonth, 12),
                        type: 'is-warning'
                    },
                    {
                        date: new Date(2017, thisMonth, 16),
                        type: 'is-danger'
                    },
                    new Date(2017, thisMonth, 20),
                    {
                        date: new Date(2017, thisMonth, 29),
                        type: 'is-success'
                    },
                    {
                        date: new Date(2017, thisMonth, 29),
                        type: 'is-warning'
                    },
                    {
                        date: new Date(2017, thisMonth, 29),
                        type: 'is-info'
                    }
                ],
                unselectableDates: [
                    new Date(2017, thisMonth, 29)
                ],
                bars: false
            }
        }
    }
</script>