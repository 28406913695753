<template>
  <!-- <page-wrapper :title="title"> -->

  <div>
    <div class="control">
      <b-switch v-model="hideNumLine"> Hide Numbers Line </b-switch>
    </div>
    <h1>{{totalScore}}/10</h1>
    <section>
      <b-steps
        type="is-info"
        v-model="activeStep"
        :mobile-mode="mobileMode">
          <b-step-item  v-for="(word, i) in shuffledWords" :key="i" :class="setBackgroundColor(i)" style="padding: 30px;"
          :step="(i+1)" label="" clickable>
            <h1 class="title has-text-centered">{{word.text1}}</h1>
            <div class="block has-text-left" style="margin-left: 50px;">

              <div v-for="j in 4" :key="j" style="margin-top: 10px;">
                <b-radio
                v-model="answerArray[i]"
                :native-value="j"
                :name="'word_'+(i+1)"
                :disabled="isShowAnswer(i)">
                <span style="color: blue; font-weight: bold;">{{ word['ques'+j] }} </span>
                <!-- {{ word['ques'+j] }} -->
                <span v-show="isShowAnswer(i)">
                  <span v-if="word.answer==j"><fa-icon icon="check" class="correct-ans-cl"/></span>
                  <span v-else><fa-icon icon="xmark" class="wrong-ans-cl"/></span>
                </span>
              </b-radio>
              </div>
              
            </div>
            <div style="height: 2rem; font-weight: bold;">
              <span v-show="isShowAnswer(i)">
                <span v-if="isTrueAnswer(i)" class="correct-ans-cl">CORRECT!</span>
                <span v-else class="wrong-ans-cl">WRONG!</span>
              </span>
            </div>
          </b-step-item>

          <template
              #navigation="{previous, next}">
              <b-button class="nav-btn"
                  type="is-warning"
                  icon-pack="fas"
                  icon-left="backward"
                  :disabled="previous.disabled"
                  @click.prevent="previous.action">
                  Previous
              </b-button>
              <b-button class="nav-btn"
                  type="is-success"
                  icon-pack="fas"
                  icon-right="forward"
                  :disabled="next.disabled"
                  @click.prevent="next.action">
                  Next
              </b-button>
          </template>
      </b-steps>
      <!-- <h1>Ans: {{ answerArray }}</h1>
      <h1>Ans: {{ hideNumLine }}</h1> -->
    </section>

  <!-- </page-wrapper> -->
  </div>
</template>

<script>
// import PageWrapper from '@/components/wrappers/PageWrapper.vue'
const WORDS = [
    {
        "text1": "駐車",
        "text2": "ちゅうしゃ",
        "ques1": "ちゅしゃ",
        "ques2": "ちゅうしゃ",
        "ques3": "しゅしゃ",
        "ques4": "しゅうしゃ",
        "answer": "2"
    },
    {
        "text1": "駐車場",
        "text2": "ちゅうしゃじょう",
        "ques1": "ちゅしゃば",
        "ques2": "ちゅしゃじょう",
        "ques3": "ちゅうしゃば",
        "ques4": "ちゅうしゃじょう",
        "answer": "4"
    },
    {
        "text1": "無い",
        "text2": "ない",
        "ques1": "ない",
        "ques2": "なしい",
        "ques3": "あい",
        "ques4": "かい",
        "answer": "1"
    },
    {
        "text1": "無休",
        "text2": "むきゅう",
        "ques1": "むきゅ",
        "ques2": "むきゅう",
        "ques3": "むしゅ",
        "ques4": "むしゅう",
        "answer": "2"
    },
    {
        "text1": "無理",
        "text2": "むり",
        "ques1": "しゅうり",
        "ques2": "しゅり",
        "ques3": "うり",
        "ques4": "むり",
        "answer": "4"
    },
    {
        "text1": "無料",
        "text2": "むりょう",
        "ques1": "しゅうりょう",
        "ques2": "しゅりょ",
        "ques3": "むりょう",
        "ques4": "むりょ",
        "answer": "3"
    },
    {
        "text1": "満車",
        "text2": "まんしゃ",
        "ques1": "ちゅうしゃ",
        "ques2": "めんしゃ",
        "ques3": "まんしゃ",
        "ques4": "かんしゃ",
        "answer": "3"
    },
    {
        "text1": "満員",
        "text2": "まんいん",
        "ques1": "めんいん",
        "ques2": "まんいん",
        "ques3": "めんかい",
        "ques4": "まんかい",
        "answer": "2"
    },
    {
        "text1": "不満",
        "text2": "ふまん",
        "ques1": "ぶめん",
        "ques2": "ぶまん",
        "ques3": "ふめん",
        "ques4": "ふまん",
        "answer": "4"
    },
    {
        "text1": "方向",
        "text2": "ほうこう",
        "ques1": "ほうこう",
        "ques2": "ほこう",
        "ques3": "ほうこ",
        "ques4": "ほこ",
        "answer": "1"
    },
    {
        "text1": "向こう",
        "text2": "むこう",
        "ques1": "かいこう",
        "ques2": "こうこう",
        "ques3": "むこう",
        "ques4": "うこう",
        "answer": "3"
    },
    {
        "text1": "向かう",
        "text2": "むかう",
        "ques1": "かいかう",
        "ques2": "むかう",
        "ques3": "うかう",
        "ques4": "こうかう",
        "answer": "2"
    },
    {
        "text1": "〇〇向き",
        "text2": "〇〇むき",
        "ques1": "〇〇うき",
        "ques2": "〇〇こうき",
        "ques3": "〇〇かいき",
        "ques4": "〇〇むき",
        "answer": "4"
    },
    {
        "text1": "禁止",
        "text2": "きんし",
        "ques1": "きんし",
        "ques2": "きんち",
        "ques3": "きんせい",
        "ques4": "きんじょう",
        "answer": "1"
    },
    {
        "text1": "関心",
        "text2": "かんしん",
        "ques1": "もんしん",
        "ques2": "へいしん",
        "ques3": "かんしん",
        "ques4": "けんしん",
        "answer": "3"
    },
    {
        "text1": "関する",
        "text2": "かんする",
        "ques1": "けんする",
        "ques2": "かんする",
        "ques3": "へんする",
        "ques4": "はんする",
        "answer": "2"
    },
    {
        "text1": "関係",
        "text2": "かんけい",
        "ques1": "けんけい",
        "ques2": "かんけい",
        "ques3": "けんしん",
        "ques4": "かんしん",
        "answer": "2"
    },
    {
        "text1": "係",
        "text2": "かかり",
        "ques1": "けい",
        "ques2": "かん",
        "ques3": "いたり",
        "ques4": "かかり",
        "answer": "4"
    },
    {
        "text1": "無断",
        "text2": "むだん",
        "ques1": "むだん",
        "ques2": "むたん",
        "ques3": "ぶだん",
        "ques4": "ぶたん",
        "answer": "1"
    },
    {
        "text1": "断水",
        "text2": "だんすい",
        "ques1": "だんすう",
        "ques2": "たんすう",
        "ques3": "だんすい",
        "ques4": "たんすい",
        "answer": "3"
    },
    {
        "text1": "断る",
        "text2": "ことわる",
        "ques1": "やめる",
        "ques2": "つづける",
        "ques3": "おりる",
        "ques4": "ことわる",
        "answer": "4"
    }
]


export default {
  name: 'N3Kanji',
  components: {
    // PageWrapper,
  },
  data() {
    return {
      title: {
        icon: 'highlighter',
        name: 'Test'
      },
      words: WORDS,
      totalScore: 0,
      // score: 0,
      activeStep: 0,
      answerArray: [],
      hideNumLine: false
    }
  },
  async created() {
    
  },
  computed: {
    mobileMode: function(){
      let result = ''
      if(this.hideNumLine){
        result = 'minimalist'
      } else {
        result = null
      }
      return result;
    },
    shuffledWords: function(){
      let result = this.shuffle(this.words)
      console.log(result)
      return result.slice(0, 10)
    }
    // totalScore: {
    //   get: function(){
    //     return this.score
    //   },
    //   set: function (newVal){
    //     this.score += newVal
    //   }
    // },
  },
  methods: {
    isShowAnswer(i){
      return this.answerArray[i] != null;
    },
    setBackgroundColor(i){
      let c_name = 'no-ans-bg'
      if (this.answerArray[i] != null){
        if (this.answerArray[i] == this.words[i].answer){
          c_name = 'correct-ans-bg'
        } else {
          c_name = 'wrong-ans-bg'
        }
      }
      return c_name;
    },
    isTrueAnswer(i){
      // if (this.answerArray[i] == this.words[i].answer){
      //     this.totalScore = 1;
      //   }
      return this.answerArray[i] == this.words[i].answer;
    },
    shuffle(array) {
      let currentIndex = array.length,  randomIndex;

      // While there remain elements to shuffle.
      while (currentIndex != 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }

      return array;
    },
  }
}
</script>



<style>
#submit-button{
  width: 120px;
  /* float: right; */
  margin: 20px;
}
.nav-btn{
  width: 100px;
  margin: auto 30px
}
.correct-ans-cl{
  color: green;
  text-shadow: 1px 1px #646464;
}
.wrong-ans-cl{
  color: red;
}
.no-ans-bg{
  background-color: rgb(202, 239, 253);
}
.correct-ans-bg{
  background-color: rgb(209, 253, 191);
}
.wrong-ans-bg{
  background-color: rgb(255, 182, 182);
}
</style>
